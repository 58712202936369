import React, { useState, useEffect, useCallback} from 'react';

import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import IconButton from '@mui/material/IconButton';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import Rating from '@mui/material/Rating';

import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';

import Format from './../Format';
import Token from './../Token';
import EventBus from './../EventBus';

import Snackbar from '@mui/material/Snackbar';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

interface Product {
  id: number;
  uuid: string;
  name: string;
  img: string;
  rating: number;
  original: string;
  count: number;
  oldPrice: number;
  price: number;
}

interface ProductCardProps {
  router: ToolpadRouter;
  product: Product;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        valueIsNumericString
        prefix=""
      />
    );
  },
);

export default function ProductCardPage({ router, product }: ProductCardProps) {
  const [count, setCount] = useState(1);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarPosition] = useState({
    vertical: 'top' as 'top' | 'bottom',
    horizontal: 'center' as 'left' | 'center' | 'right',
  });
  const [openCountDialog, setOpenCountDialog] = useState(false);
  const [countOnCart, setCountOnCart] = useState(0);

  const handleAdd = () => {
    setCount((prev) => (prev < product.count ? prev + 1 : prev));
  };

  const handleRemove = () => {
    setCount((prev) => (prev - 1 === 0 ? 1 : prev - 1));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleClickOpenCountDialog = () => {
    setOpenCountDialog(true);
  };

  const handleCloseCountDialog = () => {
    setOpenCountDialog(false);
  };

  const handleChangeCount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value)) {
      setCount(value);
    }
  };

  const addToCart = async () => {
    if (count <= 0) {
      return;
    }

    try {
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const token = (new Token()).get();
      if (token) {
        headers.append('Token', token);
      }

      const response = await fetch('/api/cart/add', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ uuid: product.uuid, count: count, amount: count * product.price }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      if (result.status === "OK") {
        EventBus.emit('cartUpdated', true);
        setSnackbarOpen(true);
        getCountOnCart();
      }
    } catch (error) {
      console.error('Ошибка добавления в корзину:', error);
    }
  };

  const getCountOnCart = useCallback(async () => {
    try {
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const token = new Token().get();
      if (token) {
        headers.append('Token', token);
      }

      const response = await fetch(`/api/cart/product/count/${product.uuid}`, {
        method: 'POST',
        headers: headers,
      });

      if (response.ok) {
        const result = await response.json();
        setCountOnCart(result.count);
      } else {
        setCountOnCart(0);
      }
    } catch (error) {
      console.error('Error fetching cart count:', error);
      setCountOnCart(0);
    }
  }, [product.uuid]);

  useEffect(() => {
    getCountOnCart();
  }, [product.uuid, getCountOnCart]);

  return (
    <>
      <Card>

        <CardContent>
          <Grid container size={12} spacing={{ xs: 2, md: 3 }}>
            <Grid
              size={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
              }}
            >
              <img
                src={`/api/image/1/${product.uuid}`}
                alt={product.name}
                style={{
                  width: "135px",
                  height: "160px",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                onClick={() => router.navigate('/product?uuid=' + product.uuid)}
              />

              {countOnCart > 0 &&
                <ShoppingCartIcon color="action"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 5,
                    width: "50px",
                    height: "25px",
                    zIndex: 9999,
                  }} />
              }

              {product.price !== product.oldPrice &&
                <img
                  src="./images/sale.png"
                  alt="Sale"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: "15%",
                    width: "50px",
                    height: "25px",
                    transform: "rotate(20deg)",
                  }}
                />}
            </Grid>

            <Grid size={12} sx={{ width: '100%', height: '40px' }}>
              <Typography sx={{ fontSize: '.7rem', fontWeight: 'bold' }} onClick={() => router.navigate('/product?uuid=' + product.uuid)}>{product.name}</Typography>
            </Grid>

            <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>
              <Grid size="grow" style={{ textAlign: 'left' }}>
                <Typography sx={{ fontSize: '0.6rem' }}>{product.original}</Typography>
              </Grid>
              <Grid size="grow" style={{ textAlign: 'right' }}>
                {product.count === 0 && (
                  <Typography sx={{ fontSize: '0.5rem', color: 'red' }}>Нет в наличии</Typography>
                )}
                {product.count > 0 && product.count <= 10 && (
                  <Typography sx={{ fontSize: '0.6rem' }}>Кол:<span style={{ paddingLeft: 5, color: "green" }}>{product.count}</span></Typography>
                )}
                {product.count > 10 && (
                  <Typography sx={{ fontSize: '0.6rem' }}>Кол:<span style={{ paddingLeft: 5, color: "green" }}>10+</span></Typography>
                )}
              </Grid>

              <Grid size={12}>
                <Rating defaultValue={product.rating} precision={0.5} />
              </Grid>
            </Grid>

            <Grid size={12} style={{ textAlign: 'right' }}>
              {product.count > 0 ? (
                <>
                  {product.price !== product.oldPrice ? (
                    <Typography sx={{ fontSize: '0.75rem', fontWeight: 'bold', textDecoration: 'line-through' }}><Format value={product.oldPrice} /><span style={{ paddingLeft: 5 }}>₸</span></Typography>
                  ) : (
                    <Typography sx={{ fontSize: '0.75rem', fontWeight: 'bold' }} >&nbsp;</Typography>
                  )}
                  <Typography sx={{ fontWeight: 'bold' }}><Format value={product.price} /><span style={{ paddingLeft: 5 }}>₸</span></Typography>
                </>
              ) : (
                <>
                  <Typography sx={{ fontSize: '0.75rem', fontWeight: 'bold', textDecoration: 'line-through' }}></Typography>
                  <Typography sx={{ fontWeight: 'bold' }}></Typography>
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>

        <CardActions disableSpacing sx={{ minHeight: 100 }}>
          {product.count > 0 && product.price > 0 ? (
            <Grid container size="grow" spacing={1} >
              <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>
                <Grid size="grow" style={{ marginLeft: 5, textAlign: 'left' }}>
                  <IconButton aria-label="add" style={{ background: 'grey' }} onClick={handleAdd}>
                    <AddIcon />
                  </IconButton>
                </Grid>

                <Grid size="grow" style={{ textAlign: 'center' }}>
                  <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', textDecoration: 'underline' }} onClick={handleClickOpenCountDialog}>
                    {count}
                  </Typography>
                </Grid>

                <Grid size="grow" style={{ marginRight: 5, textAlign: 'right' }}>
                  <IconButton aria-label="remove" style={{ background: 'grey' }} onClick={handleRemove}>
                    <RemoveIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid size={12}>
                <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
                  <Button variant="contained" sx={{ width: '100%', fontSize: '0.6rem', fontWeight: 'bold' }} onClick={addToCart}>Добавить в корзину</Button>
                </Stack>
              </Grid>
            </Grid>
          ) : (
            <span style={{ position: 'relative', minHeight: 135, minWidth: 200 }}>
              <span style={{ position: 'absolute', bottom: 5 }}>
                <Grid size={12} style={{ textAlign: 'right' }}>
                  {product.price !== product.oldPrice ? (
                    <Typography sx={{ fontSize: '0.75rem', fontWeight: 'bold', textDecoration: 'line-through' }}>
                      <Format value={product.oldPrice} /><span style={{ paddingLeft: 5 }}>₸</span>
                    </Typography>
                  ) : (
                    <Typography sx={{ fontSize: '0.75rem', fontWeight: 'bold', textDecoration: 'line-through' }}>
                      &nbsp;
                    </Typography>
                  )}
                  <Typography sx={{ fontWeight: 'bold' }}>
                    <Format value={product.price} /><span style={{ paddingLeft: 5 }}>₸</span>
                  </Typography>
                </Grid>
              </span>
            </span>
          )}
        </CardActions>

      </Card>

      <Snackbar
        anchorOrigin={snackbarPosition}
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={handleSnackbarClose}
        message="Добавлено в корзину"
      />

      <Dialog open={openCountDialog} onClose={handleCloseCountDialog}>
        <DialogContent>
          <DialogContentText>
            <TextField
              label="Количество"
              value={count}
              onChange={handleChangeCount}
              name="numberformat"
              slotProps={{
                input: {
                  inputComponent: NumericFormatCustom as any,
                },
              }}
              variant="standard"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCountDialog}>Отмена</Button>
          <Button onClick={() => { handleCloseCountDialog(); }} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}

