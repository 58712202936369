import * as React from 'react';
import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';

import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import { styled } from '@mui/material/styles';

import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';

import Format from './../Format';

import Token from './../Token';

dayjs.locale('ru');

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 8,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface Document {
  id: string;
  date: string;
  number: string;
  amount: number;
  status: string;
}

interface DocumentHistoryProps {
  router: ToolpadRouter;
}

async function loadUserHistory(startDate: dayjs.Dayjs, endDate: dayjs.Dayjs): Promise<Document[]> {
  try {
    var body = JSON.stringify({ begin: startDate.format('DD-MM-YYYY'), end: endDate.format('DD-MM-YYYY') });

    const response = await fetch(`/api/user/retail/history/${new Token().get()}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: body,
    });

    if (response.ok) {
      return await response.json();
    } else {
      return [];
    }
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    return [];
  }
}

export default function DocumentHistory({ router }: DocumentHistoryProps) {
  const [documents, setDocuments] = React.useState<Document[]>([]);

  const [startDate, setStartDate] = React.useState<dayjs.Dayjs>(dayjs().startOf('month'));
  const [endDate, setEndDate] = React.useState<dayjs.Dayjs>(dayjs().endOf('month'));

  const handleStartDateChange = async (date: dayjs.Dayjs | null) => {
    if (date) {
      const startOfDay = date.startOf('day');
      setStartDate(startOfDay);
      const documents = await loadUserHistory(startOfDay, endDate);
      setDocuments(documents);
    }
  };

  const handleEndDateChange = async (date: dayjs.Dayjs | null) => {
    if (date) {
      const endOfDay = date.endOf('day');
      setEndDate(endOfDay);
      const documents = await loadUserHistory(startDate, endOfDay);
      setDocuments(documents);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const documents = await loadUserHistory(startDate, endDate);
      setDocuments(documents);
    };
    fetchData();
  }, [startDate, endDate]);

  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function getStatus(status: string) {
    switch (status) {
      case 'ASSEMBLY': {
        return 'НА СБОРКЕ'
      }
      case 'CANCELLED': {
        return 'ОТМЕНЕН'
      }
      case 'COMPLETED': {
        return 'ЗАВЕРШЕН'
      }
    }
  }

  return (
    <Grid container size={12} spacing={{ xs: 2, md: 3 }}>

      <Grid size={12}>

        <Grid container size={12} spacing={{ xs: 2, md: 3 }}>

          <Grid size={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
              <MobileDatePicker defaultValue={dayjs().startOf('month')} onChange={handleStartDateChange} />
            </LocalizationProvider>
          </Grid>

          <Grid size={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
              <MobileDatePicker defaultValue={dayjs().endOf('month')} onChange={handleEndDateChange} />
            </LocalizationProvider>
          </Grid>

        </Grid>

        <Box sx={{ height: 15 }}></Box>

        <Grid size={12}>

          <TableContainer component={Paper}>

            <Table>

              <TableHead>
                <TableRow>
                  <StyledTableCell width={'25%'} align="center">Номер</StyledTableCell>
                  <StyledTableCell width={'25%'} align="center">Дата</StyledTableCell>
                  <StyledTableCell width={'25%'} align="center">Сумма</StyledTableCell>
                  <StyledTableCell width={'25%'} align="center">Статус</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {documents?.map((document) => (
                  <StyledTableRow key={document.id} onClick={() => router.navigate('/document?uuid=' + document.id)}>
                    <StyledTableCell component="th" scope="row">{document.number}</StyledTableCell>
                    <StyledTableCell align="left">{formatDate(document.date)}</StyledTableCell>
                    <StyledTableCell align="right"><Format value={document.amount} /><span style={{ paddingLeft: 1 }}>₸</span></StyledTableCell>
                    <StyledTableCell align="left">{getStatus(document.status)}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>

            </Table>

          </TableContainer>

        </Grid>
      </Grid>
    </Grid>
  );
}