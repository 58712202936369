import React from 'react';
import Grid from '@mui/material/Grid2';
import { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';
import Box from '@mui/material/Box';

interface Catalog {
  uuid: string;
  name: string;
  count: number;
  child: Catalog[];
}

interface CatalogPageProps {
  router: ToolpadRouter;
}

async function fetchCatalogs(): Promise<Catalog[]> {
  try {
    const response = await fetch("/api/group", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      console.error('Failed to fetch catalogs');
      return [];
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching catalogs:', error);
    return [];
  }
}

export default function CatalogPage({ router }: CatalogPageProps) {
  const [catalogs, setCatalogs] = useState<Catalog[]>([]);

  useEffect(() => {
    async function loadCatalogs() {
      const fetchedCatalogs = await fetchCatalogs();
      setCatalogs(fetchedCatalogs);
    }

    loadCatalogs();
  }, []);


  const renderCatalog = (catalog: Catalog) => {
    const uuid = catalog.uuid;
    const hasChilds = Array.isArray(catalog.child) && catalog.child.length > 0;

    return (
      <>
        {hasChilds ? (
          <Accordion key={catalog.uuid}>
            <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
              <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>
                <Grid size="grow" style={{ textAlign: 'left' }}>
                  <Typography onClick={() =>  router.navigate('/catalog?uuid=' + uuid)} style={{cursor: 'pointer'}}>
                    {catalog.name}
                  </Typography>
                </Grid>
                <Grid size="grow" style={{ textAlign: 'right' }}>
                  <Typography>
                    <span style={{ right: 0 }}>({catalog.count})</span>
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {catalog.child.map((child) => (
                <React.Fragment key={child.uuid}>{renderCatalog(child)}</React.Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        ) : (
          <Accordion key={catalog.uuid}>
            <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>
              <Grid size="grow" style={{ textAlign: 'left' }}>
                <Typography sx={{ fontSize: '0.8rem', padding: 2 }} onClick={() => router.navigate('/catalog?uuid=' + uuid)} style={{cursor: 'pointer'}}>
                  {catalog.name}
                </Typography>
              </Grid>
              <Grid size="grow" style={{ textAlign: 'right' }}>
                <Typography>
                  <span style={{ paddingRight: 10 }}>({catalog.count})</span>
                </Typography>
              </Grid>
            </Grid>
          </Accordion>
        )
        }
      </>
    );
  };

  return (
    <>
      <Box sx={{ height: 15 }} />
      {catalogs.map((catalog) => (
        <React.Fragment key={catalog.uuid}>{renderCatalog(catalog)}</React.Fragment>
      ))}
    </>
  );
}
