import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

import FileIcon from '@mui/icons-material/Article';

const events = [
    { year: 2000, text: 'Компания с 2000 года поставляет запчасти на корейские автомобили с рынка Южной Кореи' },
    { year: 2009, text: 'Компания становиться официальный дистрибьютером всемирно известной компании CTR Южная Корея' },
    { year: 2010, text: '"Пятый Элемент" подписывает контракт и становиться эксклюзивным дистрибьютером завода SHIN HWA (Южная Корея) в Казахстане и России' },
    { year: 2012, text: 'В 2012 компания получила эксклюзивное право поставлять и продавать в Казахстане продукцию Тайваньского завода UIL FU под брендом CENTRAL' },
    { year: 2018, text: 'В 2018 году, компания запустила проекты как Интернет-магазин, Call центр и электронная очередь. В данный момент стоит задача по развитию данных направлений' },
    { year: 2019, text: '2019 повышение квалификации сотрудников отдела продаж, открытие склада площадью 1200 м2, завоз нового бреда YUIL FILTER' },
    { year: 2020, text: '2020 установка терминала самообслуживания, создание отдела доставки' },
    { year: 2021, text: '2021 Открытие новых точек по г. Алматы' },
    { year: 2022, text: 'Открытие филиала на Райымбека 257а. Внедрение бренд элемента Feal. Титульное спонсорство вице чемпиона по дрифту Михаил Пак' },
    { year: 2023, text: 'Мы - титульный спонсор компании Burnout Z. Пилот команды - Михаил Пак занимает титул чемпиона Казахстана по дрифту 2023г.' },
    { year: 2024, text: 'Продолжаем развиваться для Вас!' },
];

export default function AboutPage() {
    const [text, setText] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

    const handleClickOpen = (scrollType: DialogProps['scroll'], fileName: string) => async () => {
        try {
            const response = await fetch('/doc/' + fileName);
            const data = await response.text();

            setText(data);
        } catch (error) {
            console.error('Error fetching file:', error);
        }

        setOpenDialog(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };
    return (
        <>
            <Grid container size={12} spacing={{ xs: 2, md: 3 }}>
                <Grid size={6}>
                    <img src='./images/about/1.jpg' width={'100%'} alt="ABOUT" />
                </Grid>
                <Grid size={6}>
                    <Typography sx={{ width: '100%', textAlign: 'justify' }}>
                        Компания “Пятый элемент” - это большой магазин оптовой и розничной торговли с удобным расположением в центре города Алматы и парковкой для клиентов.
                    </Typography>
                </Grid>
                <Grid size={12}>
                    <Typography sx={{ textIndent: '30px', width: '100%', textAlign: 'justify' }}>
                        Мы разработали собственную систему логистики, позволяющую осуществлять прямые поставки из Южной Кореи и Тайваня на регулярной основе.
                    </Typography>
                    <Typography sx={{ textIndent: '30px', width: '100%', textAlign: 'justify' }}>
                        Сотрудничаем со многими автосервисами города Алматы, а так же в регионах Казахстана.
                    </Typography>
                    <Typography sx={{ textIndent: '30px', width: '100%', textAlign: 'justify' }}>
                        Помогаем грамотно подобрать запчасти и своевременно их доставить, при этом даём гарантию на заводской брак.
                    </Typography>
                </Grid>
            </Grid>

            <Box sx={{ height: 15 }} />

            <Grid container size={12} spacing={{ xs: 2, md: 3 }}>
                <Stepper orientation="vertical" activeStep={11}>
                    {events.map((event) => (
                        <Step key={event.year} >
                            <StepLabel>
                                <Typography variant="caption" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>{event.year}</Typography>
                            </StepLabel>
                            <Typography sx={{ textIndent: '30px', width: '100%', textAlign: 'justify' }}>{event.text}</Typography>
                        </Step>
                    ))}
                </Stepper>
            </Grid>

            <Box sx={{ height: 15 }} />
            <Grid container size={12} spacing={{ xs: 2, md: 3 }}>

                <List>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <FileIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Политика конфиденциальности"
                            onClick={handleClickOpen('paper', 'PrivacyPolicy.txt')}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <FileIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Публичная оферта"
                            onClick={handleClickOpen('paper', 'PublicOffer.txt')}
                        />
                    </ListItem>
                </List>
            </Grid>


            <Dialog open={openDialog} onClose={handleClose} scroll={scroll}>
                <DialogContent>
                    <DialogContentText>
                        <span dangerouslySetInnerHTML={{ __html: text }} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>OK</Button>
                </DialogActions>
            </Dialog>


        </>

    );
}