import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Link from '@mui/material/Link';
import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';

import * as CryptoJS from 'crypto-js';

import User from './../User';
import Token from './../Token';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

interface AccountPageProps {
  router: ToolpadRouter;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask="+7 (000) 000-00-00"
        definitions={{
          '#': /[0-9]/,
        }}
        inputRef={ref}
        value={props.value}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

export default function AccountPage({ router }: AccountPageProps) {
  const [value, setValue] = React.useState('1');

  const [tel, setTel] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [open, setOpen] = React.useState(false);
  
  async function login(email: string, tel: string, password: string, router: ToolpadRouter): Promise<boolean> {
    try {
      const cryptoJs: any = CryptoJS;
  
      const hashedPassword = cryptoJs.SHA512(password).toString(CryptoJS.enc.Hex);
  
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email, tel: tel, password: hashedPassword })
      });
  
      const login = await response.json();

      if (login.status === "OK") {
        (new User()).update(login.fullName);
        (new User()).updateType(login.type);
        (new Token()).update(login.token);
  
        router.navigate('/');
      } else {
        if (!login.active) {
          router.navigate('/activation?text=' + login.status);
        }
        setMessage(login.status);
      }
      return false;
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      return false;
    }
  }
  
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleLogin = async () => {
    const isLoggedIn = await login(email, tel, password, router);
    if (isLoggedIn) {
      router.navigate('/');
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Grid container size={12} spacing={{ xs: 2, md: 3 }} sx={{ padding: 2, backgroundColor: '#FFF', borderRadius: 2, boxShadow: 2 }}>
        <Grid size={12}>
          <Typography sx={{ textIndent: '30px', width: '100%', textAlign: 'justify', fontWeight: 'bold', textTransform: 'uppercase' }}>
            Зарегистрированный клиент
          </Typography>
        </Grid>
        <Grid size={12}>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange}>
                  <Tab label="По email" value="1" />
                  <Tab label="По телефону" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Grid container size={12} spacing={{ xs: 2, md: 3 }}>
                  <Grid size={12}>
                    <FormControl variant="standard" sx={{ width: '100%' }}>
                      <InputLabel htmlFor="component-email">
                        <span style={{ paddingLeft: 5 }}>Email</span><span style={{ paddingLeft: 5, color: "red" }}>*</span>
                      </InputLabel>
                      <OutlinedInput id="component-email" sx={{ fontSize: 14 }} value={email} onChange={(e) => setEmail(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid size={12}>
                    <FormControl variant="standard" sx={{ width: '100%' }}>
                      <InputLabel htmlFor="component-email-pass">
                        <span style={{ paddingLeft: 5 }}>Пароль</span><span style={{ paddingLeft: 5, color: "red" }}>*</span>
                      </InputLabel>
                      <OutlinedInput id="component-email-pass" type="password" sx={{ fontSize: 14 }} value={password} onChange={(e) => setPassword(e.target.value)} />
                    </FormControl>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                <Grid container size={12} spacing={{ xs: 2, md: 3 }}>
                  <Grid size={12}>
                    <FormControl variant="standard" sx={{ width: '100%' }}>
                      <InputLabel htmlFor="component-tel">
                        <span style={{ paddingLeft: 5 }}>Номер телефона</span><span style={{ paddingLeft: 5, color: "red" }}>*</span>
                      </InputLabel>
                      <OutlinedInput id="component-tel" sx={{ fontSize: 14 }} inputComponent={TextMaskCustom as any} value={tel} onChange={(e) => setTel(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid size={12}>
                    <FormControl variant="standard" sx={{ width: '100%' }}>
                      <InputLabel htmlFor="component-tel-pass">
                        <span style={{ paddingLeft: 5 }}>Пароль</span><span style={{ paddingLeft: 5, color: "red" }}>*</span>
                      </InputLabel>
                      <OutlinedInput id="component-tel-pass" type="password" sx={{ fontSize: 14 }} value={password} onChange={(e) => setPassword(e.target.value)} />
                    </FormControl>
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
        <Grid size={12}>
          <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid size="grow" style={{ textAlign: 'right' }}>
              <Button variant="contained" onClick={handleLogin} >Войти</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={12}>
          <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid size="grow" style={{ textAlign: 'right' }}>
              <Link component="button" variant="body2" onClick={() => router.navigate('/forgotten')}>Забыли пароль?</Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ height: 40 }} />

      <Grid container size={12} spacing={{ xs: 2, md: 3 }} sx={{ padding: 2, backgroundColor: '#FFF', borderRadius: 2, boxShadow: 2 }}>
        <Grid size={12}>
          <Typography sx={{ textIndent: '30px', width: '100%', textAlign: 'justify', fontWeight: 'bold', textTransform: 'uppercase' }}>
            Новый клиент
          </Typography>
        </Grid>

        <Grid size={12}>
          <Typography sx={{ textIndent: '30px', width: '100%', textAlign: 'justify', fontWeight: 'bold' }}>
            Регистрация
          </Typography>
          <Typography sx={{ textIndent: '30px', width: '100%', textAlign: 'justify' }}>
            Создание учетной записи поможет покупать быстрее. Вы сможете контролировать состояние заказа, а также просматривать заказы сделанные ранее.
          </Typography>
        </Grid>

        <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>
          <Grid size="grow" style={{ textAlign: 'center' }}>
            <ButtonGroup variant="contained" aria-label="Basic button group" >
              <Button onClick={() => router.navigate('/register/retail')}>Розничный</Button>
              <Button onClick={() => router.navigate('/register/wholesale')}>Оптовый</Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose} >
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}>
              {message}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </>

  );
}