import { useState } from 'react';

import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import DialogContentText from '@mui/material/DialogContentText';

import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';

interface ActivationDlgPageProps {
  text: string;
  router: ToolpadRouter;
}

export default function ActivationDlgPage({ router, text = '' }: ActivationDlgPageProps) {
  const [sms, setSMS] = useState('');
  const [message, setMessage] = useState(text);
  const [openDialog, setOpenDialog] = useState(true);
  const [openMessageDialog, setMessageDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCloseMessageDialog = () => {
    setMessageDialog(false);
    router.navigate('/');
  };

  const getUserData = async () => {
    try {
      const response = await fetch('/api/register/sms/' + sms, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const login = await response.json();

      setMessage(login.message);
      setMessageDialog(true);

    } catch (error) {
      console.error('Failed to fetch user data:', error);
    }
  };

  const handleOkClick = () => {
    handleClose();

    getUserData();
  };

  return (
    <>
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={3}>
              <Grid size={12}>
                <Typography sx={{ fontSize: '1rem', textAlign: 'center' }}>
                  {message}
                </Typography>
                <FormControl variant="standard" fullWidth>
                  <InputLabel htmlFor="component-email-pass">
                    <span style={{ paddingLeft: 5 }}>SMS</span><span style={{ paddingLeft: 5, color: "red" }}>*</span>
                  </InputLabel>
                  <OutlinedInput
                    id="component-name"
                    sx={{ fontSize: 14 }}
                    value={sms}
                    onChange={(e) => setSMS(e.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOkClick} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openMessageDialog} onClose={handleCloseMessageDialog}>
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ fontSize: '1rem', textAlign: 'center' }}>
              <span dangerouslySetInnerHTML={{ __html: message }} />
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessageDialog} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
