import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, IconButton, Autocomplete } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import ProductCardPage from './ProductCardPage';
import CircularProgress from '@mui/material/CircularProgress';
import Token from './../Token';
import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';

interface Word {
  word: string;
}

interface SearchComponentProps {
  router: ToolpadRouter;
  words?: Word[];
}

interface Product {
  id: number;
  uuid: string;
  name: string;
  price: number;
  img: string;
  rating: number;
  original: string;
  count: number;
  oldPrice: number;
  cars?: string[];
  crosscodes?: string[];
}

interface Search {
  year: number;
  value: string;
  model: string;
  manufacture: string;
}

const API_LIST = '/api/search?token=';

async function fetchApi<T>(url: string, search: Search): Promise<T | null> {
  console.log(search);

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(search),
    });

    if (!response.ok) {
      console.error(`Error: ${response.statusText}`);
      return null;
    }

    return await response.json();
  } catch (error) {
    console.error('Network error:', error);
    return null;
  }
}

const SearchPage: React.FC<SearchComponentProps> = ({ router, words = [] }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [products, setProducts] = useState<Product[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleSearchChange = (_: React.SyntheticEvent, value: string) => {
    setSearchTerm(value);
  };

  const handleSearchClick = async () => {
    setProducts([]);
    if (loading) return;
    setLoading(true);

    const search = {
      year: 0,
      value: searchTerm,
      model: '',
      manufacture: '',
    };

    const url = `${API_LIST}${new Token().get()}`;

    try {
      const data = await fetchApi<Product[]>(url, search);
      if (!data || data.length === 0) {
        setHasMore(false);
      } else {
        setProducts((prev) => [...prev, ...data]);
        setCurrent((prev) => prev + data.length);
      }
    } catch (error) {
      console.error('Ошибка при поиске:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setProducts([]);
    setCurrent(0);
    setHasMore(true);
  }, [searchTerm]);

  return (
    <>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: 2,
          backgroundColor: '#FFF',
          borderRadius: 2,
          boxShadow: 2,
        }}
      >
        <Grid size={10}>
          <Autocomplete
            freeSolo
            options={words.map((option) => option.word)}
            onInputChange={handleSearchChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Номер запчасти или наименование"
                variant="outlined"
                fullWidth
                value={searchTerm}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 3,
                    paddingRight: '40px',
                  },
                }}
              />
            )}
          />
        </Grid>

        <Grid size={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton
            color="primary"
            onClick={handleSearchClick}
            sx={{
              padding: 1,
              width: '50px',
              height: '50px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 1,
              boxShadow: 2,
              '&:hover': {
                backgroundColor: '#FFF',
              },
            }}
          >
            <SearchIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Box style={{ height: 25 }}></Box>

      <Grid size={12} container spacing={{ xs: 2, md: 3 }} >
        
        {products.length === 0 && loading && (
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress />
          </Box>
        )}

        <div style={{ position: 'fixed', height: '100vh', overflow: 'auto', paddingBottom: 280 }} ref={containerRef}>
          <Grid size={12} container spacing={{ xs: 2, md: 3 }}>
            {products.map((product) => (
              <Grid key={product.uuid} size={6}>
                <ProductCardPage router={router} product={product} />
              </Grid>
            ))}
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default SearchPage;
