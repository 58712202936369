import * as React from 'react';
import { useState } from 'react';

import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Link from '@mui/material/Link';
import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask="+7 (000) 000-00-00"
        definitions={{
          '#': /[0-9]/,
        }}
        inputRef={ref}
        value={props.value}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

interface Requisite {
  bin?: string;
  uuid?: string;
  type?: string;
  company?: string;
  address?: string;
  balance?: number;
}

interface Data {
  tel?: string;
  email?: string;
  confirm?: string;
  fullName?: string;
  password?: string;
  newsletter?: boolean,
  requisites?: Requisite[]
}

interface RegisterWholesalePageProps {
  router: ToolpadRouter;
}

export default function RegisterWholesalePage({ router }: RegisterWholesalePageProps) {
  const [data, setData] = useState<Data | null>(null);
  const [message, setMessage] = useState('');
  const [openMessage, setOpenMessage] = React.useState(false);

  const handleCloseMessage = () => {
    setOpenMessage(false);
  };

  const handleChangeInput = (field: keyof Data) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setData(prev => ({
      ...prev,
      [field]: event.target.value,
    } as Data));
  };

  function checkData() {
    if (data?.email === "") {
      setMessage("Заполните email");
      return false;
    }

    if (data?.tel === "") {
      setMessage("Заполните номер телефона");
      return false;
    }

    if (data?.fullName === "") {
      setMessage("Заполните Ф. И. О.");
      return false;
    }

    if (data?.password === "") {
      setMessage("Заполните пароль");
      return false;
    }

    if (data?.confirm === "") {
      setMessage("Повторите пароль");
      return false;
    }

    if (data?.password !== data?.confirm) {
      setMessage("Введеные пароли не совпадают");
      return false;
    }

    if (data?.requisites?.length === 0) {
      setMessage("Введите реквизиты");
      return false;
    }

    data?.requisites?.forEach((item, index) => {
      if (item.type === "LEGAL") {
        if (item.company === "") {
          setMessage("Введите наименование компании в " + (index + 1) + " строке");
          return false;
        }

        if (item.bin === "") {
          setMessage("Введите БИН/ИИН в " + (index + 1) + " строке");
          return false;
        }
      }

      if (item.type === "INDIVIDUAL") {
        if (item.address === "") {
          setMessage("Введите адрес доставки в " + (index + 1) + " строке");
          return false;
        }
      }
    });

    return true;
  }

  const addRequisite = () => {
    setData((prev) => ({
      ...prev,
      requisites: [
        ...(prev?.requisites || []),
        { bin: '', uuid: crypto.randomUUID(), type: '', company: '', address: '', balance: 0 },
      ],
    }));
  };

  const removeRequisite = (uuid: string) => {
    setData((prev) => ({
      ...prev,
      requisites: prev?.requisites?.filter((req) => req.uuid !== uuid) || [],
    }));
  };

  const handleChangeRequisite = (uuid: string, field: keyof Requisite) => (
    event: SelectChangeEvent<string>) => {
    setData((prev) => ({
      ...prev,
      requisites: prev?.requisites?.map((req) =>
        req.uuid === uuid ? { ...req, [field]: event.target.value } : req
      ),
    }));
  };

  const handleChangeRequisiteInput = (uuid: string, field: string) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setData((prev) => ({
      ...prev,
      requisites: prev?.requisites?.map((req) =>
        req.uuid === uuid ? { ...req, [field]: event.target.value } : req
      ),
    }));
  };

  const handleSend = async () => {
    if (!checkData()) {
      setOpenMessage(true);
      return;
    }

    try {
      const response = await fetch('/api/user/wholesale/create', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        const result = await response.json();

        setMessage(result.message);
        setOpenMessage(true);

        if (result.status === 'OK') {
          router.navigate('/account');
        }
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  return (
    <>
      <Grid container size={12} spacing={{ xs: 2, md: 3 }}>

        <Grid container size={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
            Регистрация
          </Typography>
        </Grid>

        <Grid container size={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '.8rem' }}>
            Если Вы уже зарегистрированы, перейдите на страницу <Link component="button" variant="body2" onClick={() => router.navigate('/account')}>авторизации</Link>.
          </Typography>
        </Grid>

        <Grid size={12}>
          <FormControl variant="standard" sx={{ width: '100%' }}>
            <InputLabel htmlFor="component-fio">
              <span style={{ paddingLeft: 5 }}>Ф. И. О.</span>
              <span style={{ paddingLeft: 5, color: "red" }}>*</span>
            </InputLabel>
            <OutlinedInput id="component-fio" sx={{ fontSize: 14 }} value={data?.fullName || ''} onChange={handleChangeInput('fullName')} />
          </FormControl>
        </Grid>

        <Grid container size={12} spacing={{ xs: 2, md: 3 }}>

          <Grid size={6}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel htmlFor="component-email">
                <span style={{ paddingLeft: 5 }}>Email</span>
                <span style={{ paddingLeft: 5, color: "red" }}>*</span>
              </InputLabel>
              <OutlinedInput id="component-email" sx={{ fontSize: 14 }} value={data?.email || ''} onChange={handleChangeInput('email')} />
            </FormControl>
          </Grid>

          <Grid size={6}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel htmlFor="component-tel">
                <span style={{ paddingLeft: 5 }}>Телефон</span>
                <span style={{ paddingLeft: 5, color: "red" }}>*</span>
              </InputLabel>
              <OutlinedInput id="component-tel" inputComponent={TextMaskCustom as any} sx={{ fontSize: 14 }} value={data?.tel || ''} onChange={handleChangeInput('tel')} />
            </FormControl>
          </Grid>

        </Grid>

        <Grid container size={12} spacing={{ xs: 2, md: 3 }}>
          <Grid size={6}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel htmlFor="component-pass">
                <span style={{ paddingLeft: 5 }}>Пароль</span>
                <span style={{ paddingLeft: 5, color: "red" }}>*</span>
              </InputLabel>
              <OutlinedInput id="component-pass" type="password" sx={{ fontSize: 14 }} value={data?.password || ''} onChange={handleChangeInput('password')} />
            </FormControl>
          </Grid>
          <Grid size={6}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel htmlFor="component-pass-pass">
                <span style={{ paddingLeft: 5 }}>Подтверждение пароля</span>
                <span style={{ paddingLeft: 5, color: "red" }}>*</span>
              </InputLabel>
              <OutlinedInput id="component-pass-pass" type="password" sx={{ fontSize: 14 }} value={data?.confirm || ''} onChange={handleChangeInput('confirm')} />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container size={12} spacing={{ xs: 2, md: 3 }}>

          <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid size="grow" style={{ textAlign: 'left' }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                Реквизиты
              </Typography>
            </Grid>

            <Grid size="grow" style={{ textAlign: 'right' }}>
              <Typography sx={{ fontSize: '1rem' }}>
                <Button variant="contained" onClick={addRequisite}>Добавить</Button>
              </Typography>
            </Grid>
          </Grid>

          {data?.requisites?.map((requisite) => (
            <Grid key={requisite.uuid} container size={12} spacing={{ xs: 2, md: 3 }} sx={{ padding: 2, backgroundColor: '#FFF', borderRadius: 2, boxShadow: 2 }}>

              <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>
                <Grid size="grow" style={{ textAlign: 'left' }}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel>
                      <span style={{ paddingLeft: 5 }}>Тип</span>
                      <span style={{ paddingLeft: 5, color: "red" }}>*</span>
                    </InputLabel>
                    <Select value={requisite.type} label="Тип" onChange={handleChangeRequisite(requisite?.uuid || '', 'type')}>
                      <MenuItem value={'LEGAL'}>Юр. лицо</MenuItem>
                      <MenuItem value={'INDIVIDUAL'}>Физ. лицо</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid size="grow" style={{ textAlign: 'right' }}>
                  <Chip
                    icon={<DeleteIcon color="action" />}
                    label="Удалить" onClick={() => removeRequisite(requisite?.uuid || '')} />
                </Grid>
              </Grid>


              <React.Fragment key={requisite.uuid}>
                <Grid container size={12} spacing={{ xs: 2, md: 3 }}>

                  {requisite.type === "LEGAL" && (
                    <>
                      <Grid size={12}>
                        <FormControl variant="standard" sx={{ width: '100%' }}>
                          <InputLabel htmlFor={requisite?.uuid + "-component-company"} >
                            <span style={{ paddingLeft: 5 }}>Наименование</span>
                            <span style={{ paddingLeft: 5, color: "red" }}>*</span>
                          </InputLabel>
                          <OutlinedInput id={requisite?.uuid + "-component-company"} sx={{ fontSize: 14 }} value={requisite?.company || ''} onChange={handleChangeRequisiteInput(requisite?.uuid || '', 'company')} />
                        </FormControl>
                      </Grid>
                      <Grid size={6}>
                        <FormControl variant="standard" sx={{ width: '100%' }}>
                          <InputLabel htmlFor={requisite?.uuid + "-component-bin"}>
                            <span style={{ paddingLeft: 5 }}>БИН/ИИН</span>
                            <span style={{ paddingLeft: 5, color: "red" }}>*</span>
                          </InputLabel>
                          <OutlinedInput id={requisite?.uuid + "-component-bin"} sx={{ fontSize: 14 }} value={requisite?.bin || ''} onChange={handleChangeRequisiteInput(requisite?.uuid || '', 'bin')} />
                        </FormControl>
                      </Grid>
                      <Grid size={6}>
                        <FormControl variant="standard" sx={{ width: '100%' }}>
                          <InputLabel htmlFor={requisite?.uuid + "-component-address"}>
                            <span style={{ paddingLeft: 5 }}>Адрес</span>
                            <span style={{ paddingLeft: 5, color: "red" }}>*</span>
                          </InputLabel>
                          <OutlinedInput id={requisite?.uuid + "-component-address"} sx={{ fontSize: 14 }} value={requisite?.address || ''} onChange={handleChangeRequisiteInput(requisite?.uuid || '', 'address')} />
                        </FormControl>
                      </Grid>
                    </>
                  )}

                  {requisite.type === "INDIVIDUAL" && (
                    <Grid size={12}>
                      <FormControl variant="standard" sx={{ width: '100%' }}>
                        <InputLabel htmlFor={requisite?.uuid + "-component-address"}>
                          <span style={{ paddingLeft: 5 }}>Адрес</span>
                          <span style={{ paddingLeft: 5, color: "red" }}>*</span>
                        </InputLabel>
                        <OutlinedInput id={requisite?.uuid + "-component-address"} sx={{ fontSize: 14 }} value={requisite?.address || ''} onChange={handleChangeRequisiteInput(requisite?.uuid || '', 'address')} />
                      </FormControl>
                    </Grid>
                  )}

                </Grid>
              </React.Fragment>

            </Grid>
          ))}
        </Grid>


        <Grid size={12}>
          <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid size="grow" style={{ textAlign: 'right' }}>
              <Button variant="contained" onClick={handleSend}>Зарегистрироваться</Button>
            </Grid>
          </Grid>
        </Grid>

      </Grid>

      <Dialog open={openMessage} onClose={handleCloseMessage} >
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}>
              {message}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessage}>OK</Button>
        </DialogActions>
      </Dialog>

    </>
  );
}