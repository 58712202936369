import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import IconButton from '@mui/material/IconButton';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Snackbar from '@mui/material/Snackbar';

import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';

import Format from './../Format';
import Token from './../Token';
import EventBus from './../EventBus';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Rating from '@mui/material/Rating';

interface Cards {
  model: string,
  manufacturer: string,
  years: string[],
}

interface Product {
  uuid: string;
  name: string;
  img: string;
  original: string;
  count: number;
  rating: number;
  oldPrice: number;
  price: number;
  card: Cards[];
  numbers: string[];
}

interface ProductCardProps {
  router: ToolpadRouter;
  uuid: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

async function fetchProduct(uuid: string): Promise<Product | null> {
  try {
    const response = await fetch(`/api/product/${uuid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      console.error('Failed to fetch product');
      return null;
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching product:', error);
    return null;
  }
}

export default function ProductPage({ router, uuid }: ProductCardProps) {
  const theme = useTheme();
 
  const [open, setOpen] = React.useState(false);
  const [product, setProduct] = useState<Product | null>(null);
  const [count, setCount] = useState(1);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarPosition] = useState({
    vertical: 'top' as 'top' | 'bottom',
    horizontal: 'center' as 'left' | 'center' | 'right',
  });
  const [countOnCart, setCountOnCart] = useState(0);
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const getCountOnCart = useCallback(async () => {
    try {
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const token = (new Token()).get();
      if (token) {
        headers.append('Token', token);
      }

      const response = await fetch(`/api/cart/product/count/${product?.uuid}`, {
        method: 'POST',
        headers: headers,
      });

      if (response.ok) {
        const result = await response.json();
        setCountOnCart(result.count);
      }
    } catch (error) {
      setCountOnCart(0);
    }
  }, [product?.uuid]);

  useEffect(() => {
    const fetchData = async () => {
      if (uuid) {
        const productData = await fetchProduct(uuid);
        setProduct(productData);

        if (productData) {
          await getCountOnCart();
        }
      }
    };

    fetchData();
  }, [router.searchParams, uuid, getCountOnCart]);

  if (!product) {
    return <></>;
  }

  const handleAdd = () => {
    if (product !== null)
      setCount((prev) => (prev < product.count ? prev + 1 : prev));
  };

  const handleRemove = () => {
    setCount((prev) => (prev - 1 === 0 ? 1 : prev - 1));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const addToCart = async () => {
    if (count <= 0) {
      return;
    }

    try {
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const token = (new Token()).get();
      if (token) {
        headers.append('Token', token);
      }

      const response = await fetch('/api/cart/add', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ uuid: product.uuid, count: count, amount: count * product.price }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      if (result.status === "OK") {
        EventBus.emit('cartUpdated', true);
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Ошибка добавления в корзину:', error);
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container size={12} spacing={{ xs: 2, md: 3 }}>
            <Grid size={12} style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative'
            }}>
              <img
                src={`/api/image/1/${product.uuid}`}
                onError={(e) => (e.currentTarget.src = './images/nophoto.png')}
                alt={product.name}
                style={{
                  width: "100%", height: "100%", objectFit: "contain",
                }}
                onClick={() => handleClickOpen()}
              />

              {countOnCart > 0 &&
                <ShoppingCartIcon color="action"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 9999,
                  }} />
              }

              {product.price !== product.oldPrice &&
                <img
                  src="./images/sale.png"
                  alt="Sale"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: "15%",
                    width: "75px",
                    height: "50px",
                    transform: "rotate(20deg)",
                  }}
                />}
            </Grid>

            <Grid size={12} sx={{ width: '100%', height: '40px' }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>{product.name}</Typography>
            </Grid>

            <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>
              <Grid size="grow" style={{ textAlign: 'left' }}>
                <Typography sx={{ fontSize: '1rem' }}>{product.original}</Typography>
              </Grid>
              <Grid size="grow" style={{ textAlign: 'right' }}>
                {product.count === 0 && (
                  <Typography sx={{ fontSize: '1rem' }}><span style={{ paddingLeft: 5, color: "red" }}>Нет в наличии</span></Typography>
                )}
                {product.count > 0 && product.count <= 10 && (
                  <Typography sx={{ fontSize: '1rem' }}>Кол:<span style={{ paddingLeft: 5, color: "green" }}>{product.count}</span></Typography>
                )}
                {product.count > 10 && (
                  <Typography sx={{ fontSize: '1rem' }}>Кол:<span style={{ paddingLeft: 5, color: "green" }}>10+</span></Typography>
                )}
              </Grid>
              <Grid size={12}>
                <Rating defaultValue={product.rating} precision={0.5} />
              </Grid>
            </Grid>

            <Grid size={12} style={{ textAlign: 'right' }}>
              {product.price !== product.oldPrice ? (
                <Typography sx={{ fontSize: '0.75rem', fontWeight: 'bold', textDecoration: 'line-through' }}><Format value={product.oldPrice} /><span style={{ paddingLeft: 5 }}>₸</span></Typography>
              ) : (
                <Typography>&nbsp;</Typography>
              )}
              <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}><Format value={product.price} /><span style={{ paddingLeft: 5 }}>₸</span></Typography>
            </Grid>
          </Grid>
        </CardContent>

        <CardActions disableSpacing>
          {product.count > 0 && product.price > 0 ? (
            <Grid container size="grow" spacing={1}>
              <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>
                <Grid size="grow" style={{ marginLeft: 20, textAlign: 'left' }}>
                  <IconButton aria-label="add" style={{ background: 'grey' }} onClick={handleAdd}>
                    <AddIcon />
                  </IconButton>
                </Grid>

                <Grid size="grow" style={{ textAlign: 'center' }}>
                  <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                    {count}
                  </Typography>
                </Grid>

                <Grid size="grow" style={{ marginRight: 20, textAlign: 'right' }}>
                  <IconButton aria-label="remove" style={{ background: 'grey' }} onClick={handleRemove}>
                    <RemoveIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Box style={{ height: 100 }}></Box>

              <Grid size={12} alignItems="center" justifyContent="center">
                <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
                  <Button
                    variant="contained"
                    sx={{ width: '100%', fontSize: '1rem', fontWeight: 'bold' }}
                    onClick={addToCart}
                    disabled={product.count === 0}>
                    Добавить в корзину
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          ) : (
            <Grid container size={12} spacing={1} alignItems="center" justifyContent="center" sx={{ height: '72px' }}></Grid>
          )}
        </CardActions>

        {(product.numbers.length > 0 || product.card.length > 0) && (
          <CardContent>
            {product.numbers.length > 0 && (
              <>
                <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                  Кросс-номера:
                </Typography>
                <Stack spacing={2}>
                  {product.numbers
                    .reduce<string[][]>((rows, number, index) => {
                      if (index % 3 === 0) rows.push([]);
                      rows[rows.length - 1].push(number);
                      return rows;
                    }, [])
                    .map((row, rowIndex) => (
                      <Stack direction="row" spacing={2} key={rowIndex}>
                        {row.map((number: string, index: number) => (
                          <Box key={index} flex={1}>
                            <Item>{number}</Item>
                          </Box>
                        ))}
                      </Stack>
                    ))}
                </Stack>
              </>
            )}

            <Box style={{ height: 25 }}></Box>

            {product.card.length > 0 && (
              <>
                <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                  Машины:
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Производитель</StyledTableCell>
                        <StyledTableCell align="center">Марка</StyledTableCell>
                        <StyledTableCell align="right">Год</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {product.card.map((row, index) => (
                        <StyledTableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <StyledTableCell component="th" scope="row">{row.manufacturer}</StyledTableCell>
                          <StyledTableCell align="center">{row.model}</StyledTableCell>
                          <StyledTableCell align="right">{row.years[0]}-{row.years[row.years.length - 1]}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </CardContent>
        )}

        <Snackbar
          anchorOrigin={snackbarPosition}
          open={snackbarOpen}
          autoHideDuration={1000}
          onClose={handleSnackbarClose}
          message="Добавлено в корзину"
        />

        <Dialog open={open} onClose={handleClose} fullScreen={fullScreen} >
          <DialogContent>
              <Grid container size={12} spacing={{ xs: 2, md: 3 }}>
                <Grid size={12} style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative'
                }}>
                  <img
                    src={`/api/image/1/${product.uuid}`}
                    onError={(e) => (e.currentTarget.src = './images/nophoto.png')}
                    alt={product.name}
                    style={{
                      width: "100%", height: "100%", objectFit: "contain",
                    }}
                  />

                  {countOnCart > 0 &&
                    <ShoppingCartIcon color="action"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 9999,
                      }} />
                  }

                  {product.price !== product.oldPrice &&
                    <img
                      src="./images/sale.png"
                      alt="Sale"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: "15%",
                        width: "75px",
                        height: "50px",
                        transform: "rotate(20deg)",
                      }}
                    />}
                </Grid>
              </Grid>
          </DialogContent>
        </Dialog>

      </Card>
    </>
  );
}
