class Discount {
  constructor() {
    if (localStorage.getItem('discount') === null) {
      this.update(false);
    }
  }

  get(): boolean {
    return localStorage.getItem('discount') === 'true';
  }

  update(value: boolean): void {
    localStorage.setItem('discount', value.toString());
  }

  delete(): void {
    localStorage.removeItem('discount');
  }
}

export default Discount;
