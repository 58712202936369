import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

interface ErrorPageProps {
  message: string;
}

export default function ErrorPage({ message }: ErrorPageProps) {
  return (
    <Grid size={12} style={{ textAlign: 'left' }}>
      <Typography sx={{ fontSize: '1rem' }}>
        {message}
      </Typography>
    </Grid>
  );
}