import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

const addresses = [
    { text: 'г. Алматы, ул. Емцова 26а (по пр. Рыскулова, напротив ТЦ "Бакорда")' },
    { text: 'г. Алматы, пр. Райымбека, 257а' },
    { text: 'г. Алматы, ул. Жамбыла, 204, 1 этаж' },
];
const telphones = [
    { text: '+7 (771) 218 11 02' },
    { text: '+7 (727) 390 91 91' },
];
const work = [
    { text: 'Пн-пт: 9:30-18:00' },
    { text: 'Сб: 9:30-16:00' },
];

export default function AboutPage() {

    return (
        <>
            <Grid container size={12} spacing={{ xs: 2, md: 3 }}>
                <Typography sx={{ width: '100%', textAlign: 'justify', fontWeight: 'bold' }}>Адреса:</Typography>
                {addresses.map((adres) => (
                <Grid size={12} key={adres.text}>
                    <Typography sx={{paddingmaLeft: 3, width: '100%', textAlign: 'justify' }}>
                        {adres.text}
                    </Typography>
                </Grid>
                ))}
            </Grid>

            <Box sx={{ height: 15 }} />

            <Grid container size={12} spacing={{ xs: 2, md: 3 }}>
                <Typography sx={{ width: '100%', textAlign: 'justify', fontWeight: 'bold'}}>Телефоны:</Typography>
                {telphones.map((telphone) => (
                <Grid size={12} key={telphone.text}>
                    <Typography sx={{paddingLeft: 3, width: '100%', textAlign: 'justify' }}>
                        <a href={'tel:+' + telphone.text}>{telphone.text}</a>
                    </Typography>
                </Grid>
                ))}
            </Grid>

            <Box sx={{ height: 15 }} />

            <Grid container size={12} spacing={{ xs: 2, md: 3 }}>
                <Typography sx={{ width: '100%', textAlign: 'justify', fontWeight: 'bold' }}>Время работы:</Typography>
                {work.map((time) => (
                <Grid size={12} key={time.text}>
                    <Typography sx={{paddingLeft: 3, width: '100%', textAlign: 'justify' }}>
                        {time.text}
                    </Typography>
                </Grid>
                ))}
            </Grid>

        </>

    );
}