import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogContentText from '@mui/material/DialogContentText';

import Token from './../Token';
import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask="+7 (000) 000-00-00"
        inputRef={ref}
        value={props.value}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

interface OrderCallPageProps {
  router: ToolpadRouter;
}

export default function OrderCallPage({ router }: OrderCallPageProps) {
  const [tel, setTel] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(true);

  const [openMessageDialog, setMessageDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);

    if ((tel === '') && (name === '')) {
      router.navigate('/');
    }
  };

  const handleCloseMessageDialog = () => {
    setMessageDialog(false);
    router.navigate('/');
  };

  const getUserData = async () => {
    try {
      const response = await fetch('/api/user/' + new Token().get(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setTel(result.tel);
      setName(result.fullName);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    }
  };

  const handleRequestCall = async () => {
    try {
      const response = await fetch('/api/requestCall', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tel, name }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      setMessage(result.message);

      setMessageDialog(true);
    } catch (error) {
      console.error('Failed to send request:', error);
      router.navigate('/');
    }
  };

  const handleOkClick = () => {
    handleRequestCall();
    handleClose();
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={3}>
              <Grid size={12}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel htmlFor="component-name">
                    <span style={{ paddingLeft: 5 }}>Ф. И. О.</span>
                    <span style={{ paddingLeft: 5, color: 'red' }}>*</span>
                  </InputLabel>
                  <OutlinedInput
                    id="component-name"
                    sx={{ fontSize: 14 }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid size={12}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel htmlFor="component-tel">
                    <span style={{ paddingLeft: 5 }}>Номер телефона</span>
                    <span style={{ paddingLeft: 5, color: 'red' }}>*</span>
                  </InputLabel>
                  <OutlinedInput
                    id="component-tel"
                    sx={{ fontSize: 14 }}
                    inputComponent={TextMaskCustom as any}
                    value={tel}
                    onChange={(e) => setTel(e.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOkClick} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openMessageDialog} onClose={handleCloseMessageDialog}>
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}>
              <span dangerouslySetInnerHTML={{ __html: message }} />
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessageDialog} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
