import UserTypeValues, { UserType } from './UserType';

class User {
  name: string;
  type: UserType;

  constructor() {
    this.name = this.get() || ''; 
    this.type = this.getType() || UserTypeValues.UNDEFINED;
  }

  get(): string | null {
    return localStorage.getItem('name');
  }

  update(name: string): void {
    localStorage.setItem('name', name);
  }

  getType(): UserType | null {
    const type = localStorage.getItem('type');
    return type && Object.values(UserTypeValues).includes(type as UserType) ? (type as UserType) : UserTypeValues.UNDEFINED;
  }
    
  updateType(type: string): void {
    localStorage.setItem('type', type);
  }

  delete(): void {
    localStorage.removeItem('type');
    localStorage.removeItem('name');
  }

  getFullName(): string {
    const fullName = this.get()?.trim().split(' ').filter(Boolean) || [];

    if (fullName.length === 3) {
      const firstName = fullName[0];
      const middleName = fullName[1];
      const lastName = fullName[2];

      return `${firstName} ${middleName} ${lastName}`;
    }
    else if (fullName.length === 2) {
      const firstName = fullName[0];
      const middleName = fullName[1];

      return `${firstName} ${middleName}`;
    }
    else if (fullName.length === 1) {
      const firstName = fullName[0];

      return `${firstName}`;
    }
    return this.name;
  }
}

export default User;
