import { v4 as uuidv4 } from 'uuid';

class Token {
  constructor() {
    if (localStorage.getItem('token') === null) {
      this.update(uuidv4());
    }
  }

  get(): string | null {
    return localStorage.getItem('token');
  }

  update(token: string): void {
    localStorage.setItem('token', token);
  }

  delete(): void {
    localStorage.removeItem('token');
  }
}

export default Token;
