import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EventBus from './EventBus'
import Token from './Token';

interface ToolpadRouter {
  pathname: string;
  searchParams: URLSearchParams;
  navigate: (path: string | URL) => void;
}

const CartIcon: React.FC<{ router: ToolpadRouter }> = ({ router }) => {
  const [count, setCount] = useState<number>(0);

  const getCount = async () => {
    try {
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      
      const token = (new Token()).get();
      if (token) {
        headers.append('Token', token);
      }

      const response = await fetch('/api/cart/count', {
        method: 'POST',
        headers: headers,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch cart count');
      }

      const data = await response.json();
      setCount(data.count || 0);
    } catch (error) {
      console.error(error);
      setCount(0);
    }
  };

  useEffect(() => {
    const handleCartUpdated = () => {
      getCount();
    };

    EventBus.on('cartUpdated', handleCartUpdated);

    getCount();

    return () => {
      EventBus.off('cartUpdated', handleCartUpdated);
    };
  }, []);

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 2 }}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <span
        style={{
          position: 'absolute',
          right: 20,
          top: 10,
          zIndex: 99999,
        }}
      >
        <Grid style={{ textAlign: 'right' }}>
          <Chip
            icon={
              <Badge badgeContent={count} color="primary">
                <ShoppingCartIcon color="action" />
              </Badge>
            }
            label="Корзина"
            onClick={() => router.navigate('/cart')}
          />
        </Grid>
      </span>
    </Grid>
  );
};

export default CartIcon;