import Document from './Document';
import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';

interface Requisite {
  bin?: string;
  uuid?: string;
  type?: string;
  company?: string;
  address?: string;
  balance?: number;
}

interface User {
  id: string;
  type: string;
  tel: string;
  payNull: boolean;
  fullName: string;
  category: string;
  requisites: Requisite[];
}

interface Cart {
  client: string;
  user: User;
  tel: string;
  pay: string;
  payNumber: string;
  comment: string;
  delivery: string;
  deliveryAddress: string;
  fullName: string;
  email: string;
  amount: number;
  items: CartItem[];
}

interface CartItem {
  name: string;
  product: string;
  count: number;
  price: number;
}

const HalykBank = (cart: Cart, router: ToolpadRouter) => {
  const TerminalID = "3de6a3f2-a687-4e68-9c0d-aab2d1fab3e0";
  const ClientID = "FE.KZ";
  const ClientSecret = "7CIuiYrN3qjPX$jj";
  const url = "https://epay-oauth.homebank.kz/oauth2/token";

  const loadPaymentScript = (): void => {
    const script = document.createElement('script');
    script.src = "https://epay.homebank.kz/payform/payment-api.js";
    script.async = true;
    script.onload = () => {
      makePayment();
    };
    script.onerror = () => {
      console.error('Failed to load Halyk Bank Payment API script.');
    };
    document.body.appendChild(script);
  };

  const makePayment = async (): Promise<void> => {
    const data = new URLSearchParams();
    data.append('grant_type', 'client_credentials');
    data.append('scope', 'payment usermanagement');
    data.append('client_id', ClientID);
    data.append('client_secret', ClientSecret);
    data.append('invoiceID', cart?.payNumber);
    data.append('amount', cart?.amount.toString());
    data.append('currency', 'KZT');
    data.append('terminal', TerminalID);

    const options: RequestInit = {
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const auth = await response.json();

      const paymentObject = createPaymentObject(auth, cart.payNumber, cart.amount);

      if ((window as any).halyk) {
        (window as any).halyk.showPaymentWidget(paymentObject, callBk);
      } else {
        console.error('Halyk API is not available');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const createPaymentObject = (auth: any, invoiceId: string, amount: number) => {
    return {
      invoiceId,
      invoiceIdAlt: invoiceId,
      backLink: "/",
      failureBackLink: "/error",
      postLink: "/checkout",
      failurePostLink: "/error",
      language: "RUS",
      description: "Оплата в интернет магазине",
      terminal: TerminalID,
      amount,
      currency: "KZT",
      auth,
    };
  };

  const callBk = (result: { success: boolean }): void => {
    if (result.success) {
      const handleCheckout = async () => {
        const documentResult = await Document({ cart });
        if (documentResult) {
          router.navigate('/checkout');
        }
      };

      handleCheckout();
    } else {
      router.navigate('/error?message=Payment failed due to an unknown error.');
    }
  };

  loadPaymentScript();
};

export default HalykBank; 