import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/joy/Typography';

export default function MainBanner() {
  return (
<Grid container size={12} sx={{ maxHeight: 150, paddingBottom: 25 }}>
  <Grid size={12} sx={{ maxHeight: 150 }}>
    <Box sx={{ position: 'relative', maxHeight: 150 }}>
      <img
        src="https://fe.kz/images/home-banner.jpg"
        alt="Banner"
        style={{ width: '100%', height: 150, transform: 'scale(1, 1)', objectFit: 'cover' }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          padding: 2,
          borderRadius: 2,
        }}
      >
        <Typography level="h4" sx={{ fontWeight: 'bold' }}>
          качественные
        </Typography>
        <Typography level="body-sm">запчасти для вашего авто</Typography>
      </Box>
    </Box>
  </Grid>
</Grid>

  );
}