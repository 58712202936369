import { PageContainer } from '@toolpad/core/PageContainer';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { AppProvider } from '@toolpad/core/AppProvider';

import THEME from './Theme';
import BRANDING from './Branding';
import NAVIGATION from './Navigation';

import CurrentRouter from './CurrentRouter';
import renderContent from './renderContent';

export default function DashboardLayoutBasic(props: any) {
  const { window } = props;
  const router = CurrentRouter('/');
  const windowApp = window ? window() : undefined;

  return (
    <AppProvider
      navigation={NAVIGATION()}
      router={router}
      theme={THEME}
      window={windowApp}
      branding={BRANDING}
    >
      <DashboardLayout>
        <PageContainer
          title={""}
        >
          {renderContent(router)}
        </PageContainer>
      </DashboardLayout>
    </AppProvider>
  );
}
