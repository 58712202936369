import * as React from 'react';

import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';

export default function CurrentRouter(initialPath: string): ToolpadRouter {
  const [pathname, setPathname] = React.useState(initialPath);
  const [searchParams, setSearchParams] = React.useState(new URLSearchParams());

  const router = React.useMemo(() => ({
    pathname,
    searchParams,
    navigate: (path: string | URL) => {
      const url = new URL(String(path), window.location.origin);
      setPathname(url.pathname);
      setSearchParams(url.searchParams);
    },
  }), [pathname, searchParams]);

  return router;
}