type EventListener<T = any> = (data?: T) => void;

interface EventBus {
  events: Record<string, EventListener[]>;

  on<T>(event: string, listener: EventListener<T>): void;
  off<T>(event: string, listener: EventListener<T>): void;
  emit<T>(event: string, data?: T): void;
}

const EventBus: EventBus = {
  events: {},

  on(event: string, listener: EventListener): void {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(listener);
  },

  off(event: string, listener: EventListener): void {
    if (!this.events[event]) return;

    this.events[event] = this.events[event].filter(l => l !== listener);
  },

  emit(event: string, data?: any): void {
    if (!this.events[event]) return;

    this.events[event].forEach(listener => listener(data));
  },
};

export default EventBus;
