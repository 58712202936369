import { useState, useEffect, useCallback, useRef } from 'react';
import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';

import Grid from '@mui/material/Grid2';
import ProductCardPage from './ProductCardPage';

interface Product {
  id: number;
  uuid: string;
  name: string;
  price: number;
  img: string;
  rating: number;
  original: string;
  count: number;
  oldPrice: number;
  cars?: string[];
  crosscodes?: string[];
}

const API_LIST = '/api/product/catalog';
const API_DISCOUNT = '/api/productListDiscount'

async function fetchApi<T>(url: string): Promise<T | null> {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
      console.error(`Error: ${response.statusText}`);
      return null;
    }

    return await response.json();
  } catch (error) {
    console.error('Network error:', error);
    return null;
  }
}

interface ProductCardProps {
  uuid?: string | null;
  router: ToolpadRouter;
}

export default function ProductsListPage({ router, uuid }: ProductCardProps) {
  const [products, setProducts] = useState<Product[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const fetchMoreData = useCallback(async () => {
    if (!hasMore) return;

    var url = ''
    if (uuid !== null) {
      url = `${API_LIST}/${uuid}/${current}/10`;
    } else {
      url = `${API_DISCOUNT}/${current}/10`;
    }
    const data = await fetchApi<Product[]>(url);

    if (!data || data.length === 0) {
      setHasMore(false);
    } else {
      setProducts((prev) => [...prev, ...data]);
      setCurrent((prev) => prev + 10);
    }
  }, [uuid, current, hasMore]);

  useEffect(() => {
    const initializeData = async () => {
      setProducts([]);
      setCurrent(0);
      setHasMore(true);
      await fetchMoreData();
    };
  
    initializeData();
  }, [uuid]);

  const handleScroll = useCallback(() => {
    if (!hasMore || !containerRef.current) return;

    const bottom = containerRef.current.scrollHeight === containerRef.current.scrollTop + containerRef.current.clientHeight;
    if (bottom) {
      fetchMoreData();
    }
  }, [hasMore, fetchMoreData]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <div style={{position: 'fixed', height: '100vh', overflow: 'auto' }} ref={containerRef}>
      <Grid container size={12} spacing={{ xs: 2, md: 3 }} sx={{paddingBottom: 50}}>
        {products.map((product) => (
          <Grid key={product.uuid} size={6}>
            <ProductCardPage router={router} product={product} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
