import React from 'react';
import { Navigation } from '@toolpad/core/AppProvider';

import InfoIcon from '@mui/icons-material/Info';
import CallIcon from '@mui/icons-material/Call';
import SearchIcon from '@mui/icons-material/Search';
import AccountIcon from '@mui/icons-material/AccountCircle';
import CatalogIcon from '@mui/icons-material/FolderOpen';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

import Badge from '@mui/material/Badge';
import EventBus from './EventBus';
import Token from './Token';
import User from './User';

class CartIconWithEventBus extends React.Component {
  state = { count: 0 };

  async fetchCartCount() {
    try {
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const token = new Token().get();
      if (token) {
        headers.append('Token', token);
      }

      const response = await fetch('/api/cart/count', {
        method: 'POST',
        headers: headers,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch cart count');
      }

      const data = await response.json();
      this.setState({ count: data.count || 0 });
    } catch (error) {
      console.error(error);
      this.setState({ count: 0 });
    }
  }

  componentDidMount() {
    this.fetchCartCount();

    EventBus.on('cartUpdated', this.fetchCartCount.bind(this));
  }

  componentWillUnmount() {
    EventBus.off('cartUpdated', this.fetchCartCount.bind(this));
  }

  render() {
    const { count } = this.state;

    return (
      <Badge badgeContent={count} color="primary">
        <ShoppingCartIcon color="action" />
      </Badge>
    );
  }
}

const NAVIGATION = () => {
  const user = new User();
  const fullName = user.getFullName();

  const navigation: Navigation = [
    {
      kind: 'header',
      title: 'Меню',
    },
    {
      segment: '',
      title: 'Главная',
      icon: <DashboardIcon />,
    },
    {
      kind: 'divider',
    },
    {
      segment: 'catalog',
      title: 'Каталог',
      icon: <CatalogIcon />,
    },
    {
      segment: 'search',
      title: 'Поиск',
      icon: <SearchIcon />,
    },
    {
      kind: 'divider',
    },
    {
      segment: 'cart',
      title: 'Корзина',
      icon: <CartIconWithEventBus />,
    },
    {
      kind: 'divider',
    },
    {
      segment: 'account',
      title: `Личный кабинет ${fullName ? `(${fullName})` : ''}`,
      icon: <AccountIcon />,
    },
    {
      kind: 'divider',
    },
    {
      segment: 'about',
      title: 'О нас',
      icon: <InfoIcon />,
    },
    {
      kind: 'divider',
    },
    {
      segment: 'contacts',
      title: 'Контакты',
      icon: <ContactSupportIcon />,
    },
    {
      kind: 'divider',
    },
    {
      segment: 'call',
      title: 'Заказать звонок',
      icon: <CallIcon />,
    },
  ];
  
  EventBus.emit('navigationUpdated');

  return navigation;
};

export default NAVIGATION;
