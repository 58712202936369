import Grid from '@mui/material/Grid2';
import Chip from '@mui/material/Chip';

import Box from '@mui/material/Box';

import SearchIcon from '@mui/icons-material/Search';
import CatalogIcon from '@mui/icons-material/FolderOpen';

import { VisibleIcons } from './VisibleIcons';

interface ToolpadRouter {
  pathname: string;
  searchParams: URLSearchParams;
  navigate: (path: string | URL) => void;
}

export default function MainIcons({ visibleIcons, router }: { visibleIcons: VisibleIcons, router: ToolpadRouter }) {
  return (
    <>
      <Grid container spacing={{ xs: 2, md: 2 }} size="grow" style={{ display: 'flex', alignItems: 'center' }}>
        {(visibleIcons & VisibleIcons.CATALOG) !== 0 && (
          <Grid size="grow" style={{ textAlign: 'left' }}>
            <Chip
              icon={<CatalogIcon />}
              label="Каталог"
              onClick={() => router.navigate('/catalog')}
              style={{width: '100%'}}
            />
          </Grid>
        )}

        {(visibleIcons & VisibleIcons.SEARCH) !== 0 && (
          <Grid size="grow" style={{ textAlign: 'right' }}>
            <Chip
              icon={<SearchIcon />}
              label="Поиск"
              onClick={() => router.navigate('/search')}
              style={{width: '100%'}}
            />
          </Grid>
        )}
      </Grid>
      <Box sx={{ height: 15 }} />
    </>
  );
}
