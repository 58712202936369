import React, { Component } from 'react';

// Utility function to format amounts
export const formatAmount = (amount: number | null): string => {
  return amount ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : '0';
};

// Define props interface
interface FormatProps {
  value: number | null;
}

// Define state interface
interface FormatState {
  value: number | null;
}

class Format extends Component<FormatProps, FormatState> {
  constructor(props: FormatProps) {
    super(props);

    this.state = {
      value: this.props.value,
    };
  }

  componentDidUpdate(prevProps: FormatProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  render() {
    const { value } = this.state;

    return <>{formatAmount(value)}</>;
  }
}

export default Format;
