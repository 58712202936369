import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';

import CartPage from './pages/CartPage';
import HomePage from './pages/HomePage';
import ErrorPage from './pages/ErrorPage';
import AboutPage from './pages/AboutPage';
import SearchPage from './pages/SearchPage';
import AccountPage from './pages/AccountPage';
import CatalogPage from './pages/CatalogPage';
import ProductPage from './pages/ProductPage';
import NotFoundPage from './pages/NotFoundPage';
import CheckoutPage from './pages/CheckoutPage';
import ContactsPage from './pages/ContactsPage';
import DocumentPage from './pages/DocumentPage';
import OrderCallPage from './pages/OrderCallPage';
import ProductsListPage from './pages/ProductsListPage';
import ActivationDlgPage from './pages/ActivationDlgPage';

import RegisterRetailPage from './pages/RegisterRetailPage';
import RegisterWholesalePage from './pages/RegisterWholesalePage';
import ForgottenPage from './pages/ForgottenPage';

import AccountRetailPage from './pages/AccountRetailPage';
import AccountWholesalePage from './pages/AccountWholesalePage';

import CartIcon from './CartIcon';
import ExitIcon from './ExitIcon';
import MainIcons from './MainIcons';
import UserTypeValues from './UserType';
import { VisibleIcons } from './VisibleIcons';
import User from './User';
import EventBus from './EventBus'

const renderContent = (router: ToolpadRouter) => {
  switch (router.pathname) {
    case '/':
      return (
        <>
          <CartIcon router={router} />
          <HomePage router={router} />
        </>)
        ;
    case '/catalog': {
      const uuid = router.searchParams.get('uuid');

      return uuid !== null && uuid !== "" ? (
        <>
          <CartIcon router={router} />
          <MainIcons visibleIcons={VisibleIcons.CATALOG} router={router} />
          <ProductsListPage router={router} uuid={uuid} />
        </>
      ) : (
        <>
          <CartIcon router={router} />
          <CatalogPage router={router} />
        </>
      );
    }
    case '/search':
      return (
        <>
          <CartIcon router={router} />
          <SearchPage router={router} words={[]} />
        </>);
    case '/cart':
      return <CartPage router={router} />;
    case '/product': {
      const uuid = router.searchParams.get('uuid');
      return uuid !== null && uuid !== "" ? (
        <>
          <CartIcon router={router} />
          <ProductPage router={router} uuid={uuid} />
        </>
      ) : (
        <NotFoundPage />
      );
    }
    case '/document': {
      const uuid = router.searchParams.get('uuid');
      return uuid !== null && uuid !== "" ? (
        <DocumentPage uuid={uuid} />
      ) : (
        <NotFoundPage />
      );
    }
    case '/checkout':
      return (
        <>
          <CartIcon router={router} />
          <CheckoutPage router={router} />
        </>);
    case '/about':
      return (<AboutPage />);
    case '/account':
      {
        EventBus.emit('navigationUpdated', true);
        switch ((new User()).type) {
          case UserTypeValues.UNDEFINED: {
            return (<AccountPage router={router} />);
          }
          case UserTypeValues.RETAIL: {
            return (
              <>
                <ExitIcon router={router} />
                <AccountRetailPage router={router} />
              </>
            );
          }
          case UserTypeValues.WHOLESALE: {
            return (
              <>
                <ExitIcon router={router} />
                <AccountWholesalePage router={router} />
              </>
            )
              ;
          }
          default: {
            return (<AccountPage router={router} />);
          }
        }
      }
    case '/account/retail':
      return (<AccountRetailPage router={router} />);
    case '/account/wholesale':
      return (<AccountWholesalePage router={router} />);
    case '/register/retail':
      return (<RegisterRetailPage router={router} />);
    case '/register/wholesale':
      return (<RegisterWholesalePage router={router} />);
    case '/forgotten':
      return (<ForgottenPage router={router} />);
    case '/contacts':
      return (<ContactsPage />);
    case '/call':
      return (<OrderCallPage router={router} />);
    case '/activation':
      const text = router.searchParams.get('text');
      return (<ActivationDlgPage router={router} text={text || ''} />);
    case '/error':
      const message = router.searchParams.get('message');
      return (<ErrorPage message={message || ''} />);

    default:
      return <NotFoundPage />;
  }
};

export default renderContent; 