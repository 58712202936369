import * as React from 'react';
import { useState } from 'react';

import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Link from '@mui/material/Link';
import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask="+7 (000) 000-00-00"
        definitions={{
          '#': /[0-9]/,
        }}
        inputRef={ref}
        value={props.value}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

interface Requisites {
  bin: string;
  company: string;
  address: string;
}

interface Data {
  tel: string;
  email: string;
  confirm: string;
  fullName: string;
  password: string;
  newsletter: boolean,
  requisites: Requisites[]
}

interface RegisterRetailPageProps {
  router: ToolpadRouter;
}

export default function RegisterRetailPage({ router }: RegisterRetailPageProps) {
  const [data, setData] = useState<Data | null>(null);
  const [message, setMessage] = useState('');
  const [openMessage, setOpenMessage] = React.useState(false);

  const handleCloseMessage = () => {
    setOpenMessage(false);
  };

  const handleChangeInput = (field: keyof Data) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setData(prev => ({
      ...prev,
      [field]: event.target.value,
    } as Data));
  };

  function checkData() {
    if (data?.tel === "") {
      setMessage("Введите номер телефона");
      return false;
    }

    if (data?.fullName === "") {
      setMessage("Введите Ф.И.О.");
      return false;
    }

    if (data?.password === "") {
      setMessage("Введите пароль");
      return false;
    }

    if (data?.confirm === "") {
      setMessage("Повторите пароль");
      return false;
    }

    if (data?.password !== data?.confirm) {
      setMessage("Введеные пароли не совпадают");
      return false;
    }

    return true;
  }

  const handleSend = async () => {
    if (!checkData()) {
      setOpenMessage(true);
      return;
    }

    try {
      const response = await fetch('/api/user/retail/create', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        const result = await response.json();

        setMessage(result.message);
        setOpenMessage(true);

        if (result.status === 'OK') {
          router.navigate('/account');
        }
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  return (
    <>
      <Grid container size={12} spacing={{ xs: 2, md: 3 }}>

        <Grid container size={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
            Регистрация
          </Typography>
        </Grid>

        <Grid container size={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '.8rem' }}>
            Если Вы уже зарегистрированы, перейдите на страницу <Link component="button" variant="body2" onClick={() => router.navigate('/account')}>авторизации</Link>.
          </Typography>
        </Grid>

        <Grid size={12}>
          <FormControl variant="standard" sx={{ width: '100%' }}>
            <InputLabel htmlFor="component-fio">
              <span style={{ paddingLeft: 5 }}>Ф. И. О.</span>
              <span style={{ paddingLeft: 5, color: "red" }}>*</span>
            </InputLabel>
            <OutlinedInput id="component-fio" sx={{ fontSize: 14 }} value={data?.fullName || ''} onChange={handleChangeInput('fullName')} />
          </FormControl>
        </Grid>

        <Grid container size={12} spacing={{ xs: 2, md: 3 }}>

          <Grid size={6}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel htmlFor="component-email">
                <span style={{ paddingLeft: 5 }}>Email</span>
                <span style={{ paddingLeft: 5, color: "red" }}>*</span>
              </InputLabel>
              <OutlinedInput id="component-email" sx={{ fontSize: 14 }} value={data?.email || ''} onChange={handleChangeInput('email')} />
            </FormControl>
          </Grid>

          <Grid size={6}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel htmlFor="component-tel">
                <span style={{ paddingLeft: 5 }}>Телефон</span>
                <span style={{ paddingLeft: 5, color: "red" }}>*</span>
              </InputLabel>
              <OutlinedInput id="component-tel" inputComponent={TextMaskCustom as any} sx={{ fontSize: 14 }} value={data?.tel || ''} onChange={handleChangeInput('tel')} />
            </FormControl>
          </Grid>

        </Grid>

        <Grid container size={12} spacing={{ xs: 2, md: 3 }}>
          <Grid size={6}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel htmlFor="component-pass">
                <span style={{ paddingLeft: 5 }}>Пароль</span>
                <span style={{ paddingLeft: 5, color: "red" }}>*</span>
              </InputLabel>
              <OutlinedInput id="component-pass" type="password" sx={{ fontSize: 14 }} value={data?.password || ''} onChange={handleChangeInput('password')} />
            </FormControl>
          </Grid>
          <Grid size={6}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel htmlFor="component-pass-pass">
                <span style={{ paddingLeft: 5 }}>Подтверждение пароля</span>
                <span style={{ paddingLeft: 5, color: "red" }}>*</span>
              </InputLabel>
              <OutlinedInput id="component-pass-pass" type="password" sx={{ fontSize: 14 }} value={data?.confirm || ''} onChange={handleChangeInput('confirm')} />
            </FormControl>
          </Grid>
        </Grid>

        <Grid size={12}>
          <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid size="grow" style={{ textAlign: 'right' }}>
              <Button variant="contained" onClick={handleSend}>Зарегистрироваться</Button>
            </Grid>
          </Grid>
        </Grid>

      </Grid>

      <Dialog open={openMessage} onClose={handleCloseMessage} >
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}>
              {message}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessage}>OK</Button>
        </DialogActions>
      </Dialog>

    </>
  );
}