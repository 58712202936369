import * as React from 'react';
import { useState } from 'react';

import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

interface ForgottenPageProps {
  router: ToolpadRouter;
}

export default function ForgottenPage({ router }: ForgottenPageProps) {
  const [tel, setTel] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [openMessage, setOpenMessage] = React.useState(false);

  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (name === 'tel') {
      setTel(event.target.value);
    }
    if (name === 'email') {
      setEmail(event.target.value);
    }
  };

  const handleCloseMessage = () => {
    setOpenMessage(false);
  };

  const handleGet = async () => {
    try {
      const response = await fetch('/api/forgotten', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ tel: tel, email: email })
      });

      if (response.ok) {
        if ((email !== "") || (tel !== "")) {
          setMessage("Новый пароль был выслан ");
          if (email !== "") {
            setMessage(message + "на ваш email.");
          }
          if (tel !== "") {
            setMessage(message + "вам по смс.");
          }
          setOpenMessage(true);
          router.navigate('/account');
        } else {
          setMessage(`Ошибка: ${response.status}`);
          setOpenMessage(true);
        }
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  return (
    <>
      <Grid container size={12} spacing={{ xs: 2, md: 3 }}>

        <Grid container size={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
            Забыли пароль?
          </Typography>
        </Grid>

        <Grid container size={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '.8rem' }}>
            Введите email вашей учетной записи или телефон.
          </Typography>
        </Grid>

        <Grid container size={12} spacing={{ xs: 2, md: 3 }}>

          <Grid size={12}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel htmlFor="component-email">
                <span style={{ paddingLeft: 5 }}>Email</span>
                <span style={{ paddingLeft: 5, color: "red" }}>*</span>
              </InputLabel>
              <OutlinedInput id="component-email" sx={{ fontSize: 14 }} value={email || ''} onChange={handleChange('email')} />
            </FormControl>
          </Grid>

          <Grid size={12}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel htmlFor="component-tel">
                <span style={{ paddingLeft: 5 }}>Телефон</span>
                <span style={{ paddingLeft: 5, color: "red" }}>*</span>
              </InputLabel>
              <OutlinedInput id="component-tel" sx={{ fontSize: 14 }} value={tel || ''} onChange={handleChange('tel')} />
            </FormControl>
          </Grid>

        </Grid>

        <Grid size={12}>
          <Typography sx={{ fontSize: '.8rem' }}>
            Нажмите кнопку "Получить", чтобы получить пароль по электронной почте или на телефон в виде смс.
          </Typography>
        </Grid>

        <Grid size={12}>
          <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid size="grow" style={{ textAlign: 'right' }}>
              <Button variant="contained" onClick={handleGet}>Получить</Button>
            </Grid>
          </Grid>
        </Grid>

      </Grid>

      <Dialog open={openMessage} onClose={handleCloseMessage} >
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}>
              {message}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessage}>OK</Button>
        </DialogActions>
      </Dialog>

    </>
  );
}