import * as React from 'react';
import { useEffect } from 'react';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import { styled } from '@mui/material/styles';

import { TextareaAutosize as TextArea } from '@mui/base/TextareaAutosize';

import Format from './../Format';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 8,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface DocumentProps {
  uuid: string;
}

interface Item {
  name: string;
  count: number;
  price: number;
  amount: number;
  original: string;
}

interface Document {
  date: string;
  table: Item[],
  status: string;
  number: string;
  amount: number;
  comment: string;
}

async function loadDocument(uuid: string): Promise<Document | null> {

  try {
    const response = await fetch(`/api/document/${uuid}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    return null;
  }
}

export default function DocumentPage({ uuid }: DocumentProps) {
  const [document, setDocument] = React.useState<Document | null>(null);

  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    const fetchData = async () => {

      const document = await loadDocument(uuid);

      setDocument(document);
    };
    fetchData();
  }, [uuid]);

  function getStatus(status: string) {
    switch (status) {
      case 'ASSEMBLY': {
        return 'НА СБОРКЕ'
      }
      case 'CANCELLED': {
        return 'ОТМЕНЕН'
      }
      case 'COMPLETED': {
        return 'ЗАВЕРШЕН'
      }
    }
  }

  return (
    <>
      <Grid container size={12} spacing={{ xs: 2, md: 3 }}>

        <Grid container size={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Grid size="grow" style={{ marginLeft: 20, textAlign: 'left' }}>
            <Typography sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
              ЗАКАЗ №{document?.number} ОТ {formatDate(document?.date ?? "")}г.
            </Typography>
          </Grid>
        </Grid>

        <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>

          <Grid size="grow" style={{ marginRight: 20, textAlign: 'right' }}>
            Статус: {getStatus(document?.status ?? '')}
          </Grid>
        </Grid>

        <Grid container size={12} spacing={{ xs: 2, md: 3 }}>
          <Grid size={12}>

            <TableContainer component={Paper}>

              <Table>

                <TableHead>
                  <TableRow>
                    <StyledTableCell width={'65%'} align="center">Наименование</StyledTableCell>
                    <StyledTableCell width={'3%'} align="center">Кол.</StyledTableCell>
                    <StyledTableCell width={'3%'} align="center">Цена</StyledTableCell>
                    <StyledTableCell width={'3%'} align="center">Сумма</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {document?.table?.map((item) => (
                    <StyledTableRow key={item.name}>
                      <StyledTableCell align="left">[{item.original}] {item.name}</StyledTableCell>
                      <StyledTableCell align="right"><Format value={item.count} /></StyledTableCell>
                      <StyledTableCell align="right"><Format value={item.price} /><span style={{ paddingLeft: 1 }}>₸</span></StyledTableCell>
                      <StyledTableCell align="right"><Format value={item.count * item.price} /><span style={{ paddingLeft: 1 }}>₸</span></StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>

              </Table>

            </TableContainer>

          </Grid>

          <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>

            <Grid size="grow" style={{ marginRight: 20, textAlign: 'right', fontWeight: 'bold' }}>
              Сумма:<span style={{ paddingLeft: 1 }}><Format value={(document?.table.reduce((total, item) => total + item.count * item.price, 0)) ?? 0} /></span><span style={{ paddingLeft: 1 }}>₸</span>
            </Grid>
          </Grid>

          <Grid size={12}>
            <Typography sx={{ fontSize: '1rem' }}>
              Комментарий:
            </Typography>
            <TextArea style={{ width: '100%' }} minRows={5} readOnly value={document?.comment} />
          </Grid>

        </Grid>

      </Grid>
    </>
  );
}