class Visit {
  get(): string | null {
    return localStorage.getItem('date');
  }

  set(): void {
    const currentDate = new Date().toISOString().split('T')[0];
    localStorage.setItem('date', currentDate);
  }

  async check(): Promise<void> {
    const current = new Date().toISOString().split('T')[0];
    const storedDate = this.get();

    if (!storedDate || current !== storedDate) {
      this.set();

      try {
        const response = await fetch('/api/user/dateCount', { method: 'GET' });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      } catch (error) { }
    }
  }
}

export default Visit;
