import * as React from 'react';
import { useState, useEffect } from 'react';

import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import TabContext from '@mui/lab/TabContext';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { TextareaAutosize as TextArea } from '@mui/base/TextareaAutosize';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import DocumentHistory from './DocumentHistory'
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';

import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';

import Token from './../Token';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask="+7 (000) 000-00-00"
        definitions={{
          '#': /[0-9]/,
        }}
        inputRef={ref}
        value={props.value}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

interface AccountWholesalePageProps {
  router: ToolpadRouter;
}

interface Requisite {
  bin?: string;
  uuid?: string;
  type?: string;
  company?: string;
  address?: string;
  balance?: number;
}

interface Data {
  fullName?: string;
  email?: string;
  tel?: string;
  password?: string;
  confirm?: string;
  requisites?: Requisite[];
}

async function loadData(): Promise<Data | null> {
  try {
    const response = await fetch(`/api/user/${new Token().get()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      console.error('Failed to fetch product');
      return null;
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching product:', error);
    return null;
  }
}

async function sendUserReview(review: string) {
  try {
    const response = await fetch(`/api/review/add/${new Token().get()}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: review
    });

    if (response.ok) {

    }

  } catch (error) { }
};

export default function AccountWholesalePage({ router }: AccountWholesalePageProps) {
  const [data, setData] = useState<Data | null>(null);
  const [value, setValue] = React.useState('1');
  const [review, setReview] = useState('');
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [openMessage, setOpenMessage] = React.useState(false);
  const [openSaveDialog, setSaveDialog] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const data = await loadData();

      if (data) {
        data.confirm = '';
        data.password = '';
      }

      setData(data);
    };
    fetchData();
  }, []);

  const sendSaveData = async (data: Data | null) => {
    if (data === null) {
      return;
    }

    try {
      const response = await fetch(`/api/user/wholesale/update/${new Token().get()}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      });

      const result = await response.json();
      if (result.message !== '') {
        setMessage(result.message);
        setOpenMessage(true);
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const handleChangeInput = (field: keyof Data) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setData(prev => ({
      ...prev,
      [field]: event.target.value,
    } as Data));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseMessage = () => {
    setOpenMessage(false);
  };

  const handleSaveDialog = () => {
    setSaveDialog(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const sendReview = () => {
    if (review.trim() !== '') {
      sendUserReview(review);
      handleClickOpen();
      setReview('')
    }
  };

  const addRequisite = () => {
    setData((prev) => ({
      ...prev,
      requisites: [
        ...(prev?.requisites || []),
        { bin: '', uuid: crypto.randomUUID(), type: '', company: '', address: '', balance: 0 },
      ],
    }));
  };

  const removeRequisite = (uuid: string) => {
    setData((prev) => ({
      ...prev,
      requisites: prev?.requisites?.filter((req) => req.uuid !== uuid) || [],
    }));
  };

  const handleChangeRequisite = (uuid: string, field: keyof Requisite) => (
    event: SelectChangeEvent<string>) => {
    setData((prev) => ({
      ...prev,
      requisites: prev?.requisites?.map((req) =>
        req.uuid === uuid ? { ...req, [field]: event.target.value } : req
      ),
    }));
  };

  const handleChangeRequisiteInput = (uuid: string, field: string) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setData((prev) => ({
      ...prev,
      requisites: prev?.requisites?.map((req) =>
        req.uuid === uuid ? { ...req, [field]: event.target.value } : req
      ),
    }));
  };

  const dialogSave = () => {
    sendSaveData(data);
  };

  return (
    <Grid container size={12} spacing={{ xs: 2, md: 3 }}>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange}>
              <Tab label="Данные" value="1" />
              <Tab label="История заказов" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">

            <Grid container size={12} spacing={{ xs: 2, md: 3 }}>

              <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>
                <Grid size="grow" style={{ textAlign: 'right' }}>
                  <Typography sx={{ fontSize: '1rem' }}>
                    <Button variant="contained" onClick={() => dialogSave()}>Сохранить</Button>
                  </Typography>
                </Grid>
              </Grid>

              <Grid size={12}>
                <FormControl variant="standard" sx={{ width: '100%' }}>
                  <InputLabel htmlFor="component-fio">
                    <span style={{ paddingLeft: 5 }}>Ф. И. О.</span>
                    <span style={{ paddingLeft: 5, color: "red" }}>*</span>
                  </InputLabel>
                  <OutlinedInput id="component-fio" sx={{ fontSize: 14 }} value={data?.fullName || ''} onChange={handleChangeInput('fullName')} />
                </FormControl>
              </Grid>

              <Grid container size={12} spacing={{ xs: 2, md: 3 }}>

                <Grid size={6}>
                  <FormControl variant="standard" sx={{ width: '100%' }}>
                    <InputLabel htmlFor="component-email">
                      <span style={{ paddingLeft: 5 }}>Email</span>
                      <span style={{ paddingLeft: 5, color: "red" }}>*</span>
                    </InputLabel>
                    <OutlinedInput id="component-email" sx={{ fontSize: 14 }} value={data?.email || ''} onChange={handleChangeInput('email')} />
                  </FormControl>
                </Grid>

                <Grid size={6}>
                  <FormControl variant="standard" sx={{ width: '100%' }}>
                    <InputLabel htmlFor="component-tel">
                      <span style={{ paddingLeft: 5 }}>Телефон</span>
                      <span style={{ paddingLeft: 5, color: "red" }}>*</span>
                    </InputLabel>
                    <OutlinedInput id="component-tel" inputComponent={TextMaskCustom as any} sx={{ fontSize: 14 }} value={data?.tel || ''} onChange={handleChangeInput('tel')} />
                  </FormControl>
                </Grid>

              </Grid>

              <Grid container size={12} spacing={{ xs: 2, md: 3 }}>
                <Grid size={6}>
                  <FormControl variant="standard" sx={{ width: '100%' }}>
                    <InputLabel htmlFor="component-pass">
                      <span style={{ paddingLeft: 5 }}>Пароль</span>
                      <span style={{ paddingLeft: 5, color: "red" }}>*</span>
                    </InputLabel>
                    <OutlinedInput id="component-pass" type="password" sx={{ fontSize: 14 }} value={data?.password || ''} onChange={handleChangeInput('password')} />
                  </FormControl>
                </Grid>
                <Grid size={6}>
                  <FormControl variant="standard" sx={{ width: '100%' }}>
                    <InputLabel htmlFor="component-pass-pass">
                      <span style={{ paddingLeft: 5 }}>Подтверждение пароля</span>
                      <span style={{ paddingLeft: 5, color: "red" }}>*</span>
                    </InputLabel>
                    <OutlinedInput id="component-pass-pass" type="password" sx={{ fontSize: 14 }} value={data?.confirm || ''} onChange={handleChangeInput('confirm')} />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container size={12} spacing={{ xs: 2, md: 3 }}>

                <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>
                  <Grid size="grow" style={{ textAlign: 'left' }}>
                    <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                      Реквизиты
                    </Typography>
                  </Grid>

                  <Grid size="grow" style={{ textAlign: 'right' }}>
                    <Typography sx={{ fontSize: '1rem' }}>
                      <Button variant="contained" onClick={addRequisite}>Добавить</Button>
                    </Typography>
                  </Grid>
                </Grid>

                {data?.requisites?.map((requisite) => (
                  <Grid key={requisite.uuid} container size={12} spacing={{ xs: 2, md: 3 }} sx={{ padding: 2, backgroundColor: '#FFF', borderRadius: 2, boxShadow: 2 }}>

                    <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>
                      <Grid size="grow" style={{ textAlign: 'left' }}>
                        <FormControl sx={{ width: '100%' }}>
                          <InputLabel>
                            <span style={{ paddingLeft: 5 }}>Тип</span>
                            <span style={{ paddingLeft: 5, color: "red" }}>*</span>
                          </InputLabel>
                          <Select value={requisite.type} label="Тип" onChange={handleChangeRequisite(requisite?.uuid || '', 'type')}>
                            <MenuItem value={'LEGAL'}>Юр. лицо</MenuItem>
                            <MenuItem value={'INDIVIDUAL'}>Физ. лицо</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid size="grow" style={{ textAlign: 'right' }}>
                        <Chip
                          icon={<DeleteIcon color="action" />}
                          label="Удалить" onClick={() => removeRequisite(requisite?.uuid || '')} />
                      </Grid>
                    </Grid>


                    <React.Fragment key={requisite.uuid}>
                      <Grid container size={12} spacing={{ xs: 2, md: 3 }}>

                        {requisite.type === "LEGAL" && (
                          <>
                            <Grid size={12}>
                              <FormControl variant="standard" sx={{ width: '100%' }}>
                                <InputLabel htmlFor={requisite?.uuid + "-component-company"} >
                                  <span style={{ paddingLeft: 5 }}>Наименование</span>
                                  <span style={{ paddingLeft: 5, color: "red" }}>*</span>
                                </InputLabel>
                                <OutlinedInput id={requisite?.uuid + "-component-company"} sx={{ fontSize: 14 }} value={requisite?.company || ''} onChange={handleChangeRequisiteInput(requisite?.uuid || '', 'company')} />
                              </FormControl>
                            </Grid>
                            <Grid size={6}>
                              <FormControl variant="standard" sx={{ width: '100%' }}>
                                <InputLabel htmlFor={requisite?.uuid + "-component-bin"}>
                                  <span style={{ paddingLeft: 5 }}>БИН/ИИН</span>
                                  <span style={{ paddingLeft: 5, color: "red" }}>*</span>
                                </InputLabel>
                                <OutlinedInput id={requisite?.uuid + "-component-bin"} sx={{ fontSize: 14 }} value={requisite?.bin || ''} onChange={handleChangeRequisiteInput(requisite?.uuid || '', 'bin')} />
                              </FormControl>
                            </Grid>
                            <Grid size={6}>
                              <FormControl variant="standard" sx={{ width: '100%' }}>
                                <InputLabel htmlFor={requisite?.uuid + "-component-address"}>
                                  <span style={{ paddingLeft: 5 }}>Адрес</span>
                                  <span style={{ paddingLeft: 5, color: "red" }}>*</span>
                                </InputLabel>
                                <OutlinedInput id={requisite?.uuid + "-component-address"} sx={{ fontSize: 14 }} value={requisite?.address || ''} onChange={handleChangeRequisiteInput(requisite?.uuid || '', 'address')} />
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        {requisite.type === "INDIVIDUAL" && (
                          <Grid size={12}>
                            <FormControl variant="standard" sx={{ width: '100%' }}>
                              <InputLabel htmlFor={requisite?.uuid + "-component-address"}>
                                <span style={{ paddingLeft: 5 }}>Адрес</span>
                                <span style={{ paddingLeft: 5, color: "red" }}>*</span>
                              </InputLabel>
                              <OutlinedInput id={requisite?.uuid + "-component-address"} sx={{ fontSize: 14 }} value={requisite?.address || ''} onChange={handleChangeRequisiteInput(requisite?.uuid || '', 'address')} />
                            </FormControl>
                          </Grid>
                        )}

                      </Grid>
                    </React.Fragment>

                  </Grid>
                ))}
              </Grid>

              <Grid container size={12} spacing={{ xs: 2, md: 3 }}>

                <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>
                  <Grid size="grow" style={{ textAlign: 'right' }}>
                    <Typography sx={{ fontSize: '1rem' }}>
                      <Button variant="contained" onClick={() => sendReview()}>Оставить отзыв</Button>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid size={12}>
                  <TextArea style={{ width: '100%' }} minRows={10} placeholder="Отзыв" onChange={(e) => setReview(e.target.value)} />
                </Grid>
              </Grid>

            </Grid>

          </TabPanel>

          <TabPanel value="2">
            <Grid container size={12} spacing={{ xs: 2, md: 3 }}>
              <Grid size={12}>
                <DocumentHistory router={router} />
              </Grid>
            </Grid>
          </TabPanel>

        </TabContext>
      </Box>

      <Dialog open={open} onClose={handleClose} >
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}>
              Спасибо за отзыв!
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openMessage} onClose={handleCloseMessage} >
        <DialogContent>
          <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}>
            {message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessage}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openSaveDialog} onClose={handleSaveDialog}>
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}>
              Сохранить данные?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveDialog}>Нет</Button>
          <Button onClick={() => { handleSaveDialog(); dialogSave(); }} autoFocus>
            Да
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}