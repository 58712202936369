import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Chip from '@mui/material/Chip';
import ExitToApp from '@mui/icons-material/ExitToApp';

import User from './User';
import Token from './Token';

interface ToolpadRouter {
  pathname: string;
  searchParams: URLSearchParams;
  navigate: (path: string | URL) => void;
}

const ExitIcon: React.FC<{ router: ToolpadRouter }> = ({ router }) => {

  const handleExit = () => {
    (new User()).delete();
    (new Token()).delete();

    router.navigate('/')
  };

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 2 }}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <span
        style={{
          position: 'absolute',
          right: 20,
          top: 10,
          zIndex: 99999,
        }}
      >
        <Grid style={{ textAlign: 'right' }}>
          <Chip
            icon={
                <ExitToApp color="action" />
            }
            label="Выйти"
            onClick={handleExit}
          />
        </Grid>
      </span>
    </Grid>
  );
};

export default ExitIcon;