import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';
import Button from '@mui/material/Button';

interface CheckoutPageProps {
  router: ToolpadRouter;
}

export default function CheckoutPage({ router }: CheckoutPageProps) {
  return (
    <>
      <Grid container size={12} spacing={{ xs: 2, md: 3 }}>
        <Typography sx={{ fontSize: '1.5em', width: '100%', textAlign: 'justify', fontWeight: 'bold' }}>Ваш заказ принят!</Typography>

        <Typography sx={{ fontSize: '1em', width: '100%', textAlign: 'justify' }}>Если у Вас возникли вопросы, пожалуйста 
          <Link component="button" variant="body2" sx={{ fontSize: '1em', textAlign: 'justify' }} onClick={() => router.navigate('/contacts')}>свяжитесь с нами</Link>.
        </Typography>

        <Typography sx={{ fontSize: '1em', width: '100%', textAlign: 'justify' }}>Спасибо за покупки в нашем интернет-магазине!</Typography>
        
        <Grid size={12}>
          <Grid container size="grow" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid size="grow" style={{ textAlign: 'right' }}>
              <Button variant="contained" onClick={() => router.navigate('/')} >НА ГЛАВНУЮ</Button>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </>
  );
}