import React, { useEffect } from 'react';
import Dashboard from './Dashboard';
import User from './User';
import Token from './Token';
import Visit from './Visit';
import Discount from './Discount';

function App() {
  new Token();
  (new Visit()).check();

  useEffect(() => {
    const token = (new URLSearchParams(window.location.search)).get('token');
    
    const loginUser = async () => {
      if (token && token.trim() !== "") {
        const response = await fetch('/api/login/' + token, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        });

        if (response.ok) {
          const login = await response.json();

          (new User()).update(login.fullName);
          (new User()).updateType(login.type);
          (new Token()).update(login.token);

          window.location.href = '/';
        }
      }
    };

    loginUser();
  }, []);
  
  (new Discount());

  return (
    <div>
      <Dashboard />
    </div>
  );
}

export default App;
